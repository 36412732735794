<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Le producteur</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="generateProducteurPdf(data.id,camp_id)">
        Imprimer
      </el-button>
      <el-button type="info" icon="el-icon-download" @click="generateProducteurContratPdf(data.id)">Télécharger Contrat PDF</el-button>
    </div>
    <el-row>
      <el-col :span="7" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Informations personnelles</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <!--div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <pan-thumb v-if="currentProducteur.avatar !== null" :image="currentProducteur.avatar !== undefined && currentProducteur.avatar !== null ? currentProducteur.avatar : ''" :height="'100px'" :width="'100px'" :hoverable="false">
                <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data !== null ? data.nom + ' ' + data.prenom : '' }}</span>
                <span style="font-style: italic;">{{ data !== null ? data.sexe ==='f'?'Féminin':'Masculin' : '' | uppercaseFirst }}</span>
              </pan-thumb>
              <div v-else>
                <el-avatar icon="el-icon-user-solid" />
              </div>
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data !== null ? data.nom + ' ' + data.prenom : '' }}</span>
              <span style="font-style: italic;">{{ data !== null ? data.sexe ==='f'?'Féminin':'Masculin' : '' | uppercaseFirst }}</span>
              <div class="user-follow">
                <el-button
                  type="primary"
                  icon="upload"
                  style="width: 100%"
                  @click="imagecropperShow = true"
                >
                  {{ $t("user.changeAvatar") }}
                </el-button>
                <el-button
                  v-if="currentProducteur.avatar !== null"
                  type="danger"
                  style="width: 100%"
                  @click="deleteProdcteurAvatar"
                >
                  supprimer
                </el-button>
              </div>

              <image-cropper
                v-show="imagecropperShow"
                :key="imagecropperKey"
                :url="url"
                lang-type="fr"
                @close="close"
                @crop-upload-success="cropSuccess"
              />
            </div-->
            <div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <div v-if="currentProducteur.avatar !== undefined">
                <el-avatar v-if="currentProducteur.avatar !== null" style="width: 100px; height: 100px" :height="'100px'" :width="'100px'" :hoverable="false">
                  <el-image
                    :src="currentProducteur.avatar !== undefined && currentProducteur.avatar !== null ? currentProducteur.avatar : ''"
                    :preview-src-list="[ currentProducteur.avatar !== undefined && currentProducteur.avatar !== null ? currentProducteur.avatar : '' ]"
                  />
                </el-avatar>
                <!-- <pan-thumb v-if="currentProducteur.avatar !== null" :image="currentProducteur.avatar !== undefined && currentProducteur.avatar !== null ? currentProducteur.avatar : ''" :height="'100px'" :width="'100px'" :hoverable="false">
                  <el-tag :key="data.code" style="margin:5px" type="info">{{ data.code }}</el-tag>
                  <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom !== undefined ? data.nom + ' ' + data.prenom : '' }}</span>
                  <span v-if="data.sexe !== undefined" style="font-style: italic;">{{ data.sexe === 'm' ? 'Masculin' : 'Féminin' }}</span>
                </pan-thumb> -->
                <div v-else>
                  <el-avatar icon="el-icon-user-solid" />
                </div>
              </div>
              <div v-else>
                <el-avatar icon="el-icon-user-solid" />
              </div>
              <el-tag :key="data.code" style="margin:5px" type="info">{{ data.code }}</el-tag>
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom !== undefined ? data.nom + ' ' + data.prenom : '' }}</span>
              <span v-if="data.sexe !== undefined" style="font-style: italic;">{{ data.sexe === 'm' ? 'Masculin' : 'Féminin' }}</span>
              <span v-if="data.email !== undefined" style="font-style: italic;">{{ data.email }}</span>
              <span v-if="data.numero !== undefined" style="font-style: italic;">{{ data.numero }}</span>

              <div class="user-follow">
                <el-button
                  :disabled="loading"
                  type="primary"
                  icon="upload"
                  style="width: 100%"
                  @click="imagecropperShow = true"
                >
                  {{ $t("user.changeAvatar") }}
                </el-button>
                <el-button
                  v-show="loading === false"
                  v-if="data.avatar !== null"
                  type="danger"
                  style="width: 100%"
                  @click="deleteProdcteurAvatar"
                >
                  supprimer
                </el-button>
              </div>

              <image-cropper
                v-show="imagecropperShow"
                :key="imagecropperKey"
                :url="url"
                lang-type="fr"
                @close="close"
                @crop-upload-success="cropSuccess"
              />
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Autres informations</span>
            </div>
            <el-tabs tab-position="top">
              <el-tab-pane label="Organisation">
                <el-table v-loading="loading" :data="organInfos" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Parcelles">
                <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Code" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Village" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.village }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.superficie }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nbr_arb }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateParcelle(scope.row.code)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Formations suivies">
                <el-table :data="data !== null ? data.followed_trainings : []" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Theme" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.theme }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Formateur" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.formateur }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Status" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.status }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 && $t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="goToFormation(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <el-table v-loading="loading" :data="mininfos" :show-header="false">
      <el-table-column>
        <template slot-scope="scope" style="background-color: bisque">
          <span style="font-weight: bold;">{{ scope.row.name1 }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          {{ scope.row.val1 }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <span style="font-weight: bold">{{ scope.row.name2 }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          {{ scope.row.val2 }}
        </template>
      </el-table-column>
    </el-table> -->
    <!-- <table v-if="data !== null" style="border : 1px solid #000000; width:100%" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table> -->

    <!-- </el-card> -->
    <!-- <h4>Liste des Parcelles du producteur</h4>
    <el-card class="box-card filter-card just-for-admin">
      <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_arb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template>
            <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateParcelle" />
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const producteursResource = new Resource('producteurs/web');
const groupementResource = new Resource('groupements');
const villageResource = new Resource('villages');
const formationResource = new Resource('formations');
const campagnesResource = new Resource('campagne_agricoles');
const producteurRessourcePdf = new Resource('identification/producteur');
const contratRessourcePdf = new Resource('producteurs');

// import PanThumb from '@/components/PanThumb';
import ImageCropper from '@/components/ImageCropper';

export default {
  name: 'ProducteurInfo',
  components: {/* PanThumb, */ ImageCropper },
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      camp_id: null,
      data: null,
      date: '',
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      persinfos: null,
      organInfos: null,
      currentProducteur: {},
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
      imagecropperShow: false,
      imagecropperKey: 0,
      userAvatar: '',
      userid: '',
      url: '',
    };
  },
  computed: {

  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getProducteur();
    this.campEnCours();
    this.url = 'producteurs_change_profile/' + this.$route.params.id;
    console.log('the url', this.url);
  },
  methods: {
    checkPermission,
    checkRole,
    goToFormation(formation_id){
      console.log('code de la formation : ', formation_id);
      this.$router.push({ path: '/formations/' + formation_id });
    },
    cropSuccess() {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.$message({
        type: 'success',
        message: 'Mise à jours avec succès',
      });
      this.getProducteur();
    },
    close() {
      this.imagecropperShow = false;
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    async campEnCours(){
      const { data } = await campagnesResource.sublist('/en_cours');
      this.camp_id = data.id;
      sessionStorage.setItem('camp_id', this.camp_id);
      await this.$store.dispatch('camp_agricole/set_camp', this.camp_id);
      console.log('STORED CAMP ID', this.$store.getters.camp_id);
      // const camp = sessionStorage.getItem('camp_id');
    },
    async generateProducteurPdf(id, camp_id){
      var { data } = await producteurRessourcePdf.getProducteurPdf(id, camp_id);
      window.open(data, '_blank');
      console.log('PDF : ', data);
    },
    async generateProducteurContratPdf(id){
      var { data } = await contratRessourcePdf.get(id);
      if (data.contrats.length > 0) {
        window.open(data.contrats[0].lien, '_blank');
      } else {
        this.$message({
          type: 'warning',
          message: "Ce producteur n'a pas de contrat",
        });
      }
      console.log('contratPDF : ', data.contrats[0].lien);
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateParcelle(id){
      console.log('CODE DE LA PARCELLE: ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getProducteur() {
      var { data } = await producteursResource.get(this.$route.params.id);
      const followed_data = {
        producteur_id_participant: this.$route.params.id,
      };
      var followed_trainings = await formationResource.list(followed_data);
      data.followed_trainings = followed_trainings.data;
      this.parcelles = data.parcelles;
      this.data = data;
      this.currentProducteur.avatar = this.data.avatar;
      this.currentProducteur.id = this.data.id;
      console.log('PRODUCTEUR', this.data);
      this.fullscreenLoading = false;
      this.date = moment().format('DD-MM-YYYY');
      this.organInfos = [
        {
          'libelle': 'Code UP    :',
          'valeur': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
        },
        {
          'libelle': 'Région    :',
          'valeur': data.region !== null ? data.region : 'INDISPONIBLE',
        },
        {
          'libelle': 'Zone    :',
          'valeur': data.zone !== null ? data.zone : 'INDISPONIBLE',
        },
        {
          'libelle': 'Commune    :',
          'valeur': data.commune !== null ? data.commune : 'INDISPONIBLE',
        },
        {
          'libelle': 'Groupement',
          'valeur': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
        },
        {
          'libelle': 'Village',
          'valeur': data.village !== null ? data.village : 'INDISPONIBLE',
        },
      ];
      this.persinfos = [
        {
          'libelle': 'Nom du producteur    :',
          'valeur': data.nom + ' ' + data.prenom,
        },
        {
          'libelle': 'Sexe (M/F)    :',
          'valeur': data.sexe,
        },
      ];
      this.mininfos = [
        {
          'name1': 'Code UP',
          'val1': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
          'name2': 'Nom du producteur',
          'val2': data.nom + ' ' + data.prenom,
        },
        {
          'name1': 'Zone',
          'val1': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
          'name2': 'Sexe (M/F)',
          'val2': data.sexe,
        },
        {
          'name1': 'Commune',
          'val1': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
          'name2': 'Groupement',
          'val2': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
        },
        {
          'name1': 'Village',
          'val1': data.village !== null ? data.village.nom : 'INDISPONIBLE',
          'name2': 'Code Producteur',
          'val2': data.code,
        },
      ];
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
    deleteProdcteurAvatar(){
      const producteurRessource = new Resource('producteurs');

      this.$confirm(this.$t('campagneagricole.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        this.$swal({
          title: this.$t('general.SureQuestion'),
          text: this.$t('general.irrversibleMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('general.ouiSupprimer'),
        }).then((result) => {
          if (result.isConfirmed){
            producteurRessource.destroy(this.currentProducteur.id + '/delete_profile')
              .then(response => {
                this.$message({
                  type: 'success',
                  message: 'Supprimé avec succès',
                });
                this.getProducteur();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
              });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
 .user-follow {
   padding-top: 20px;
 }
</style>
